import React from "react";
import styled from "styled-components";
import { SubscribeBtn } from 'components/buttons';

import { TRIAL_DAYS } from "utils/constants";

import Header from "./BookHeader";
import BookTop from "./BookTop";
import Steps from "./Steps";
import About from "./About";


import WhyJP from "../WhyJP";
import SupportedBy from "../SupportedBy";
import MeetJP from "components/Home/MeetJP";
import FeaturedCouses from "components/Home/FeaturedCourses";
import PuddinPops from "../PuddinPops";
import Footer from "components/_core/Footer";
import FinalWords from "components/Home/FinalWords";
import Proof from "../Proof";
import CourseModal from "components/Courses/CourseModal";
import Separater from "./Separater";

export const buyLink = 'https://www.amazon.com/Drumming-Psychology-Philosophy-Improvisation/dp/B0DN6Z3LDW/ref=sr_1_4?crid=QN1B8X1U7UP2&dib=eyJ2IjoiMSJ9.VrFvDAyFw8BJqCovWjbogAeLy5Yd2DRxIyfrcjQBCey-P0yQxchqqn0QO7K5K41eybILaN9K7iG3ZADJcxO709UMgBBmrNqAbTSsGEcI_fcjWagbESzbNncrIR7RXThZvPJg1vGo_ALeAEIEOAXN0ZlF1hLCcWjOOd5hS38baP6yhgheWeAT-1qaGn1mWfSy33PLWQhiL_ZVdjgy0L--aKNNOGXwtzrKmBCtOo2IkT0.tXkDBCkP89lo3rV4X2CGDtbffq5WLAgt98X8upKp5-M&dib_tag=se&keywords=on+drumming&qid=1733660799&s=books&sprefix=on+drumming%2Cstripbooks%2C88&sr=1-4';

const CTA = styled(SubscribeBtn)`
    text-transform: none;

    padding: 13px 30px;
    width: 100%;

    max-width: 350px;
    
    border: 2px solid #fff;
    box-shadow: 0px 6px 0 0 #00000021;

    margin: 30px 0;
    font-size: 20px;

    div {
        font-style: italic;
        font-size: 15px;
    }
`;

const SatisfactionGuarantee = styled.p`
    font-weight: 500;
    color: #666;
    font-size: 14px;
    margin-top: -20px;
`;

const CTAButton = ({ whiteText }) => <>
    <CTA pill large>
        Start Improvising Now!
        {TRIAL_DAYS > 0 ? <div>Try 7 Days Free</div> : <div>Sign Up Now</div>}
    </CTA>
    <SatisfactionGuarantee style={whiteText ? { color: '#fff' }: {}}>100% Satisfaction Guarantee</SatisfactionGuarantee>
</>;

const BookLandingPage = () => {

    return (
        <div style={{ overflow: 'hidden'}}>
            <Header />

            <BookTop />
            <Steps />
            <About />
            <Separater />

           <WhyJP Button={CTAButton} />
           <Proof Button={CTAButton} />
           <SupportedBy />
           <PuddinPops Button={CTAButton} />
           <MeetJP Button={CTAButton} />
           <FeaturedCouses />
           <FinalWords />
           <Footer />
           <CourseModal />
        </div>
    );
};

export default BookLandingPage;
