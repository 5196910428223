import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
    background-color: #fff;
`;

const Container = styled.div`
    margin: 0;
    padding: 30px 15px;
    max-width: 900px;
    margin: 0 auto;

    display: flex;
    justify-content: center;

    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
`;

const Description = styled.div`
    margin-right: 40px;

    @media screen and (max-width: 700px) {
        margin-right: 0;
    }

`;

const Praise = styled.div`
    min-width: 300px;
`;

const Testimonial = styled.div`
    background-color: #ff7a57;
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0;
    color: #fff;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const TestimonialName = styled.h3`
    margin: 0;
    font-size: 1.3rem;
`;
const TestimonialSubName = styled.h4`
    margin: 0 0 5px;
    font-size: 1rem;
`;
const TestimonialText = styled.p`
    margin: 0;
    font-size: 1rem;
    line-height: 1.3em;
`;

const About = () => {
    return (
        <Wrap>
            <Container>
                <Description>
                    <h2>About the Book</h2>

                    <p><strong>“I've got a regular music education, but JP is offering completely different, deeper, more creative content that you wouldn't find in a normal music school.” - <em>Tio Nunez (JPBMethod student)</em></strong></p>

                    <p><em>* This is a book of words (a normal book), not a book of drum exercises.</em></p>

                    <p>Can creativity be learned? Can you teach someone improvisation? Or is it an innate gift for “creative people?” In this one-of-a-kind book, renowned drummer and teacher, JP Bouvet, takes you on a tour through psychology, educational theory, and, of course, specific approaches to learning the drums, to reveal how improvisation is much easier to learn than most people think. You’ll learn how to leverage the natural tendencies of your mind to systematically learn what most drummers think can’t be learned…effortless improvisational freedom. This book is for every drummer who wishes they were more creative on their instrument.</p> 

                    <p><strong>JP Bouvet</strong> is a drummer and teacher, and runs the educational website, JPBouvetMethod.com, where he specializes in creating courses that teach improvisation with step-by-step methods. He studied music at Berklee College of Music and psychology at Columbia University. In 2011, he won the two largest drum contests in the world in the same year, and went on to tour the world with artists like Steve Vai, Periphery, and others. He has since taught drums in 34 countries, and thousands of students continue to find creative freedom from his online courses.</p>

                    <p>162 pages - Dimensions: 5" x 8”</p>
                </Description>
                <Praise>
                    <h2>Praise for <em>On Drumming</em></h2>
                    <Testimonial>
                        <TestimonialName>John Riley</TestimonialName>
                        <TestimonialSubName>“The Art of Bop Drumming”"</TestimonialSubName>
                        <TestimonialText>“These unique, clear explanations and practice strategies will help any drummer play better.”</TestimonialText>
                    </Testimonial>
                    <Testimonial>
                        <TestimonialName>Matt Halpern</TestimonialName>
                        <TestimonialSubName>Periphery</TestimonialSubName>
                        <TestimonialText>“This book will not only make drummers better on their instruments, but it also has the potential to help shape them into even better, more self-aware, and more creative individuals, both on and off the kit.”</TestimonialText>
                    </Testimonial>
                    <Testimonial>
                        <TestimonialName>Steve Jocz</TestimonialName>
                        <TestimonialSubName>Sum 41</TestimonialSubName>
                        <TestimonialText>“The concepts and strategies that JP teaches in this book (and on his website) have helped me begin to unlock this incredible skill and have allowed me to fall back in love with playing the drums.”</TestimonialText>
                    </Testimonial>
                </Praise>
            </Container>
        </Wrap>
    );
};

export default About;
