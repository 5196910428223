import React from "react";
import styled from "styled-components";

import numOne from 'img/landingpage/list-num-1-book.png';
import numTwo from 'img/landingpage/list-num-2-book.png';
import numThree from 'img/landingpage/list-num-3-book.png';

const Wrap = styled.div`
    padding: 20px;
    background-color: #ff7a57;
`;

const Container = styled.div`
    max-width: 1000px;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

const ListItem = styled.div`
    margin: 20px 10px;
    display: flex;
    max-width: 500px;
    align-items: center;
    flex-basis: 33%;
    flex-shrink: 0;
`;

const ListNum = styled.img`
    margin-right: 15px;
    width: 60px;
    height: 60px;
`;

const ListContent = styled.div`
    text-align: left;

    h3 {
        margin: 0 0 3px 0;
        font-size: 16px;
    }

    p {
        margin: 0;
        font-size: 15px;
        line-height: 1.4em;
    }
`;

const Steps = () => {
    return (
        <Wrap>
            <Container>
                <ListItem>
                    <ListNum src={numOne} alt="Step 1" />
                    <ListContent>
                        <h3>Demystify improvisation:</h3>
                        <p>Learn proven educational principles that lead to improvisational freedom.</p>
                    </ListContent>
                </ListItem>
                <ListItem>
                    <ListNum src={numTwo} alt="Step 2" />
                    <ListContent>
                        <h3>Leverage Your Psychology:</h3>
                        <p>Learn how to leverage the natural tendencies of your mind to make improvisation easy and natural.</p>
                    </ListContent>
                </ListItem>
                <ListItem>
                    <ListNum src={numThree} alt="Step 3" />
                    <ListContent>
                        <h3>Make the Drums More Meaningful:</h3>
                        <p>Explore the many ways that a “drum practice” improves your life besides the literal drumming.</p>
                    </ListContent>
                </ListItem> 
            </Container>
        </Wrap>
    )
};

export default Steps;
