import React from "react";
import styled from "styled-components";
import { BuyButton } from "./BookTop";

import sepImg from './separater.png';
import YouTubeVideoThumb from 'components/Home/VideoThumb';
import { buyLink } from ".";

const BuyWrap = styled.div`
    padding: 0 0 100px 0;
    background-color: #fff;
`;

const Wrap = styled.div`
    padding: 20px;
    background-color: #0a2f35;
`;

const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;
    text-align: center;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

const SepImage = styled.img`
    width: 100%;
    max-width: 800px;
    margin: -200px auto 40px;
    display: block;

    @media screen and (max-width: 700px) {
        margin: -110px auto 40px;
    }
`;

const VideoWrap = styled.div`
    max-width: 850px;
    margin: 0 auto;
`;

const Separater = () => {
    return (
        <>
            <BuyWrap>
                <Container>
                    <BuyButton href={buyLink} className="btn" bold pill large>Buy on Amazon</BuyButton>
                </Container>
            </BuyWrap>
            <Wrap>
                <Container>
                    <SepImage src={sepImg} alt="The Book, The Website" />

                    
                    <VideoWrap>
                        <YouTubeVideoThumb videoId="968055899" isVimeo largePlayBtn backgroundThumb />
                    </VideoWrap>
                </Container>
            </Wrap>
        </>
    );
};

export default Separater;
